<template>
  <div class="card p-2">
    <h2>
      {{ $route.params.id == "new" ? "Creer un cours" : "Editer le cours" }}
    </h2>
    <form @submit.prevent="save">
      <div class="row">
        <div class="col-md-12 form-group">
          <label for="title">Titre <span class="text-danger">*</span> :</label>
          <input
            type="text"
            v-model="course.title"
            class="form-control"
            placeholder="Titre..."
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 form-group">
          <label for="title">Duree <span class="text-danger">*</span> :</label>
          <input
            type="text"
            v-model="course.duration"
            class="form-control"
            placeholder="Duree..."
          />
        </div>
        <div class="col-md-4 form-group">
          <label for="title">Prix <span class="text-danger">*</span> :</label>
          <input
            type="number"
            min="100"
            v-model="course.price"
            class="form-control"
            placeholder="Prix..."
          />
        </div>
        <div class="col-md-4 form-group">
          <label for="title">Monaie <span class="text-danger">*</span> :</label>
          <select v-model="course.currency" class="form-control form-select">
            <option value="XAF">XAF</option>
            <option value="XOF">XOF</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <label for="teacher"
            >Teacher <span class="text-danger">*</span> :</label
          >
          <select
            id="teacher"
            v-model="selectedTeacher"
            class="form-control form-select"
          >
            <option v-for="t in teachers" :key="t.id" :value="t.id">
              {{ t.firstName + " " + t.lastName }}
            </option>
          </select>
        </div>
        <div class="col-md-6 form-group">
          <label for="teacher"
            >Categorie <span class="text-danger">*</span> :</label
          >
          <select
            id="teacher"
            v-model="selectedCategory"
            class="form-control form-select"
          >
            <option v-for="c in categories" :key="c.id" :value="c.id">
              {{ c.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 form-group">
          <label for="description"
            >Video <span class="text-danger">*</span> :</label
          >
          <textarea
            id="description"
            class="form-control"
            cols="30"
            rows="2"
            v-model="course.introVideo"
          ></textarea>
        </div>
      </div>
      <div class="row" style="margin-bottom: 10em">
        <div class="col-md-12 form-group">
          <label for="description">Description:</label>

          <div id="courseEdit"></div>
        </div>
      </div>

      <div class="row mt-5" style="margin-top: 10em">
        <div class="col-md-6">
          <div class="form-group">
            <button class="btn btn-primary" type="submit">Sauvegarder</button>
            &nbsp;&nbsp;
            <button
              v-if="$route.params.id != 'new' && course.id > 0"
              class="btn btn-danger"
              type="button"
              @click="del"
            >
              Supprimmer
            </button>
            &nbsp;&nbsp;
            <button
              v-if="$route.params.id != 'new' && course.id > 0"
              class="btn btn-success ml-3"
              type="button"
              @click="openCourse"
            >
              <i class="fas fa-external-link-alt"></i> Consulter sur le site
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="card p-2" v-if="$route.params.id != 'new'">
    <div class="row">
      <form class="col-md-6" @submit.prevent="addCode">
        <h2>Codes promos</h2>
        <div class="form-group">
          <label for="code-new">Associe un nouveau code:</label>
          <select v-model="newCode" id="code-new" class="form-select">
            <option v-for="c in availableCodes" :key="c.id" :value="c.id">
              {{ c.code }}
            </option>
          </select>
          <button type="submit" class="btn btn-primary btn-sm mt-2">
            Appliquer
          </button>
        </div>
      </form>
      <div class="col-md-6" v-if="linkedCodes.length > 0">
        <h2>Codes assignes au cours</h2>
        <table class="table dt-responsive nowrap w-100">
          <thead>
            <tr>
              <td>code</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="c in linkedCodes" :key="c.id">
              <td>{{ c.code }}</td>
              <td>
                <button @click="removeCode(c)" class="btn btn-danger btn-sm">
                  Disocier
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { SITE_URL } from "../../http";
import { Quill } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";

export default {
  data() {
    return {
      editQuill: null,
      course: {
        id: 0,
        slug:"",
        title: "",
        description: "",
        price: "",
        introVideo: "",
        currency: "",
        duration: 0,
        category: 0,
        coursePicture: {
          fullSize: "",
          thumbnail: "",
          mediumSquareCrop: "",
        },
        teacher: {
          id: 0,
          firstName: "",
          lastName: "",
          profilePicture: {
            mediumSquareCrop: "",
            fullSize: "",
            thumbnail: "",
          },
        },
      },
      theToolBar: "full",
      teachers: [],
      categories: [],
      selectedTeacher: 0,
      selectedCategory: 0,
      newCode: null,
      codes: [],
      linkedCodes: [],
    };
  },
  components: {},
  methods: {
    async save() {
      let begin = this.course.introVideo.indexOf("<iframe");
      let video = this.course.introVideo;
      if (begin != -1)
        video =
          "<div>" + this.course.introVideo.substring(begin, this.course.length);
      console.log(video);
      const data = {
        ...this.course,
        teacher: this.selectedTeacher,
        introVideo: video,
        category: this.selectedCategory,
      };
      if (this.$route.params.id == "new") {
        await this.$http
          .post(`/courses/create/`, data)
          .then(() => {
            this.$store.state.courses.updated = false;
            this.reset();
            this.$toast.success("success!");
          })
          .catch(() => {
            this.$toast.error("erreur");
          });
      } else {
        data.description = this.editQuill.root.innerHTML;
        await this.$http
          .put(`/courses/${this.course.id}/update/`, data)
          .then(() => {
            this.$store.state.courses.updated = false;
            this.$router.push("/courses");
            this.$toast.success("success!");
          })
          .catch(() => {
            this.$toast.error("erreur");
          });
      }
    },
    reset() {
      this.course = {
        id: 0,
        title: "",
        description: "",
        price: "",
        introVideo: "",
        currency: "",
        duration: 0,
        category: 0,
        coursePicture: {
          fullSize: "",
          thumbnail: "",
          mediumSquareCrop: "",
        },
        teacher: {
          id: 0,
          firstName: "",
          lastName: "",
          profilePicture: {
            mediumSquareCrop: "",
            fullSize: "",
            thumbnail: "",
          },
        },
      };
    },
    async loadCourse() {
      if (this.$route.params.id != "new" && this.$route.params.id > 0) {
        await this.$http
          .get(`/courses/${this.$route.params.id}/`)
          .then((response) => {
            this.course = response.data;
            this.loadLinkedCodes();
            this.selectedTeacher = response.data.teacher.id;
            this.selectedCategory = response.data.category;
            this.createQuillForEdit();
          });
      }
    },
    async loadCodes() {
      await this.$store.dispatch("loadCodes").then((codes) => {
        this.codes = codes;
      });
    },
    openCourse() {
      window.open(
        SITE_URL + "/courses/" + this.course.id + "/show-details",
        "_blank"
      );
    },
    async loadTeachers() {
      this.$store
        .dispatch("loadTeachers")
        .then((teachers) => (this.teachers = teachers));
    },
    async del() {
      if (window.confirm("Etes vous sure de cette action?")) {
        await this.$http
          .delete(`/courses/${this.course.id}/delete`)
          .then(() => {
            this.$toast.success("success!");
            this.$store.state.courses.updated = false;
            this.$router.push({ name: "Courses" });
          })
          .catch(() => {
            this.$toast.error("Erreur lors de la supression");
          });
      }
    },
    async loadCategories() {
      await this.$store
        .dispatch("loadCategories")
        .then((categories) => (this.categories = categories));
    },
    async addCode() {
      await this.$http
        .post("/promocode/assign-or-remove-coupon-to-course", {
          course: this.course.id,
          coupon: this.newCode,
        })
        .then(() => {
          this.loadLinkedCodes();
          this.$toast.success("success!");
        });
    },
    async removeCode({ id }) {
      await this.$http
        .post("/promocode/assign-or-remove-coupon-to-course", {
          course: this.course.id,
          coupon: id,
        })
        .then(() => {
          this.loadLinkedCodes();
          this.$toast.success("success!");
        });
    },
    async loadLinkedCodes() {
      this.$http
        .get(`/promocode/promocode-list-for-one-course/${this.course.id}`)
        .then((response) => (this.linkedCodes = response.data));
    },
    createQuillForEdit() {
      var editElement = document.getElementById("courseEdit");
      // console.log("Elelement", editElement);
      var toolbarOptions = [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction

        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ["clean"], // remove formatting button
      ];

      this.editQuill = new Quill(editElement, {
        modules: {
          toolbar: toolbarOptions,
        },
        theme: "snow",
      });
      // console.log("The part in quiill creation: ", this.partContent.text);
      this.editQuill.root.innerHTML = this.course.description;
    },
  },
  computed: {
    availableCodes() {
      return this.codes.filter((c) => {
        for (let i = 0; i < this.linkedCodes.length; i++) {
          if (this.linkedCodes[i].id == c.id) return false;
        }
        return true;
      });
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.loadCourse();
      this.loadTeachers();
      this.loadCategories();
      this.loadCodes();
    });
  },
  mounted() {},
};
</script>
